.staff {
	padding: 0;
	list-style: none;
	@extend %flexbox;
	@include flex-flow(wrap);
	text-align: center;
	li {
		padding: 30px 20px;
		box-sizing: border-box;
		width: 100%;

		@media #{$tablet} {
			@include flex(1, 1, 45%);
		}

		@media #{$desktop} {
			@include flex(1, 1, 29%);
		}

	}

	.square-image {
		width: 200px;
		height: 200px;
			img {
				border-radius: 200px;
			}
	}
	
	.square-image img {
		width: 200px;
		height: 200px;
	}

	.name {
		font-size: 1.3em;
		margin-top: 20px;
	}

	.position {
		color: #666;
	}
	
	.profile {
		font-size: 0.8em;
		text-align: left;
		border: 3px solid #dfdddf;
		border-radius: 10px;
		padding: 20px;
		margin: 20px 0;
	}

	.profile li {
		padding: 0.1em 0;
		padding-left: 1.5em;
		position: relative;
		margin: 0;
		text-align: left;
		line-height: 1.5;
		list-style-type: none!important;
	}

	.profile li:before {
		left: 0;
		content: "▷";
		padding-right: 5px;
		position: absolute;

	}

}
