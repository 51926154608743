.services {
	text-align: left;
	width: 100%;
	
	img {
	  width: auto;
	  float: right;
	  margin-top: -20px;
	}

	h1,h2,h3,h4,h5 {
		margin-top: 1em;
	}
}
