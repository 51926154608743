.about {
	text-align: center;
	width: 100%;

	table.aboutus {
		//border-spacing: 10px;
		width: 100%;
	}
	table.aboutus {
		border-collapse: collapse;
	}
	table.aboutus th,
	table.aboutus td {
		border-top: 1px solid #aaa;
		border-bottom: 1px solid #aaa;
		padding: 20px 30px 20px 30px;
	}
	table.aboutus th {
		text-align: left;
		font-weight: bold;
		color: #222;
		background-color: #dedddd;
		padding-right: 10px;
		//vertical-align: top;
		//border-right: 3px solid #271106;
	}
	table.aboutus td {
		text-align: left;
		padding-left: 50px;
	}

}
